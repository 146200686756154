/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '@node_modules/@mkitio/gatsby-theme-password-protect/src/utils/utils.js';
import Logo from "@components/logo.js"
import { css } from "@emotion/core"

const PasswordProtect = () => {

  const mainClasses = css`
    background-color: #069;
    background: linear-gradient(90deg,#036,#09c);
  `

  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div css={mainClasses} className="h-full">
      <div className="sm:w-1/4 md:w-1/3 mx-auto pt-8 text-center" style={{ maxWidth: '320px' }}>
        <Logo></Logo>
        <form onSubmit={onSubmit}>
          <label htmlFor="password" className="block text-white mb-2">Enter Password</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            className="w-full bg-blue-100 p-2 mb-4 focus:outline-blue"
          />

          <button
            type="submit"
            className="btn w-full"
          >
            Enter
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordProtect;
