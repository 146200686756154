// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-checkout-canceled-js": () => import("./../../../src/pages/checkout/canceled.js" /* webpackChunkName: "component---src-pages-checkout-canceled-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout/success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-subscription-js": () => import("./../../../src/pages/manage-subscription.js" /* webpackChunkName: "component---src-pages-manage-subscription-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */)
}

